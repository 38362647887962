<template>
  <div class="common-page">
    <div class="common-search-box">
      <el-form :inline="true" class="demo-form-inline" :size="$store.state.settingInfo.size">
        <el-form-item label="小区" >
          <el-select v-model="searchParams.depId" placeholder="请选择小区" clearable  @change="onDeptChange">
            <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋" >
          <el-select v-model="searchParams.buildingId" placeholder="请选择楼栋" >
            <el-option v-for="(item, index) in buildData" :key="index" :label="item.buildingName" :value="item.buildingId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间号">
          <el-input v-model="searchParams.roomNo" placeholder="请输入房间号"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchParams.type" placeholder="请选择房屋类型" >
            <el-option v-for="(item, index) in priceList" :key="index" :label="item.title" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item><el-form-item label="开始时间" >
          <el-date-picker
            v-model="searchParams.startDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.endDate) {
                  return time.getTime() > new Date(this.searchParams.endDate).getTime();
                }
              },
            }"  
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" >
          <el-date-picker
            v-model="searchParams.endDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.startDate) {
                  return time.getTime() < new Date(this.searchParams.startDate).getTime() - 86400000 ;
                }
              },
            }"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" class="margin-left20">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="onReset" >重置</el-button>
        </el-form-item>
      </el-form>
      <div class="common-export-btn">
        <el-button type="primary" @click="onExport" :size="$store.state.settingInfo.size" :loading="btnLoading">导出</el-button>
        <el-button v-if="$store.state.userInfo.admClass == '0'" type="primary" @click="onAddShow" :size="$store.state.settingInfo.size">新增</el-button>
      </div>
    </div>
    <div class="table" ref="table">
      <el-table
        border
        :data="tableData"
        :max-height="heights"
        :size="$store.state.settingInfo.size"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="50">
        </el-table-column>
        <el-table-column
          prop="departmentDto.depName"
          label="小区"
          width="180">
        </el-table-column>
        <el-table-column
          prop="buildingNo"
          label="楼栋">
        </el-table-column>
        <el-table-column
          prop="roomDto.roomNo"
          label="房间">
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.type == 0">水费充值</span>
              <span v-if="scope.row.type == 1">电费充值</span>
              <span v-if="scope.row.type == 2">红砖</span>
              <span v-if="scope.row.type == 3">轻质砖</span>
              <span v-if="scope.row.type == 4">水泥</span>
              <span v-if="scope.row.type == 5">沙</span>
              <span v-if="scope.row.type == 6">外墙瓷砖</span>
              <span v-if="scope.row.type == 7">其他装修材料</span>
              <span v-if="scope.row.type == 8">自定义收费</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          v-if="types.indexOf(8) > -1"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="price"
          label="单价">
        </el-table-column>
        <el-table-column
          prop="number"
          label="数量">
        </el-table-column>
        <el-table-column
          prop="amount"
          label="金额">
        </el-table-column>
        <el-table-column
          width="140px"
          prop="createDate"
          label="日期">
        </el-table-column>
        <el-table-column
          prop="remark"
          width="200px"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="billNo"
          width="100"
          label="支付方式">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.paymentType == 1">自动扣款</span>
              <span v-if="scope.row.paymentType == 2">刷卡</span>
              <span v-if="scope.row.paymentType == 3">现金</span>
              <span v-if="scope.row.paymentType == 4">微信</span>
              <span v-if="scope.row.paymentType == 5">支付宝</span>
              <span v-if="scope.row.paymentType == 6">建设支付</span>
              <span v-if="scope.row.paymentType == 7">小程序</span>
              <span v-if="scope.row.paymentType == 8">业委代收</span>
              <span v-if="scope.row.paymentType == 9">银行代扣</span>
              <span v-if="scope.row.paymentType == 10">转账</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          width="100"
          v-if="$store.state.userInfo.admClass == '0'"
          label="操作">
          <template slot-scope="scope">
            <el-button
              class="common-operate-btn"
              type="text"
              @click="onEdit(scope.row)"
              size="small">
              修改
            </el-button>
            <el-button
              type="text"
              @click="onPrint(scope.row)"
              class="common-operate-btn"
              size="small">
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <CommonPage :searchParams="searchParams" @onSizChange="onSizChange" @onCurrentChange="onCurrentChange" :total="total"></CommonPage>
    <el-dialog  :title="operaType == 'add' ? '新增':'修改'" :visible.sync="dialogFormVisible">
      <div>
        <el-form :model="modalForm" label-width="120px" :size="$store.state.settingInfo.size">
          <el-form-item label="小区" >
            <el-select @change="onDeptModalChange" v-model="modalForm.depId" placeholder="请选择小区" :disabled="operaType != 'add'">
              <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="楼栋房间号" v-if="operaType == 'add'">
            <CommonRoomSelect :depId="modalForm.depId" ref="roomSelect" v-model="modalForm.roomId" ></CommonRoomSelect>
          </el-form-item>
          <el-form-item label="楼栋房间号" v-if="operaType != 'add'">
            <el-input placeholder="请输入楼栋房间号" v-model="currentInfo.buildingRoom" autocomplete="off" disabled></el-input>
          </el-form-item>
            <el-form-item label="支付方式" >
              <el-select v-model="modalForm.paymentType" placeholder="请选择支付状态" clearable>
                <el-option label="自动扣款" :value="1"></el-option>
                <el-option label="刷卡" :value="2"></el-option>
                <el-option label="现金" :value="3"></el-option>
                <el-option label="微信" :value="4"></el-option>
                <el-option label="支付宝" :value="5"></el-option>
                <el-option label="建设支付" :value="6"></el-option>
                <el-option disabled label="小程序" :value="7"></el-option>
                <el-option label="业委代收" :value="8"></el-option>
                <el-option label="银行代扣" :value="9"></el-option>
                <el-option label="转账" :value="10"></el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="类型" v-if="operaType == 'add' && types.indexOf(8) < 0">
            <el-select :multiple="true" v-model="modalForm.kind" placeholder="请选择类型" :disabled="operaType != 'add'">
              <el-option v-for="(item, index) in priceList" :key="index" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <div  v-for="(item, index) in priceList" :key="index" v-show="(currentInfo.type != 'add' && currentInfo.type == item.type) || (currentInfo.type == 'add' && modalForm.kind.indexOf(item.title) > -1)">
            <div class="tip">
              <span>
                {{ item.title }}
              </span>
              <span style="float:right">
                金额：{{ item.money }}
              </span>
            </div>
            <el-form-item label="名称" v-if="item.nameFlag">
              <el-input @blur="onFeeChange(index)" placeholder="请输入名称" v-model="item.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="单价" >
              <el-input @blur="onFeeChange(index)" placeholder="请输入单价" v-model="item.price" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="数量" >
              <el-input @blur="onFeeChange(index)" placeholder="请输入数量" v-model="item.number" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" >
              <el-input @blur="onFeeChange(index)" placeholder="请输入备注" v-model="item.remark" autocomplete="off"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :size="$store.state.settingInfo.size">取 消</el-button>
        <el-button type="primary" @click="onSure" :size="$store.state.settingInfo.size" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import minxins from '@/minxins/index'
export default{
  data () {
    return {
      form: {},
      total: 0,
      kindList: [],
      operaType: 'add',
      dialogFormVisible: false,
      tableData: [],
      loading: false,
      btnLoading: false,
      depList: [],
      modalForm: {
        id: '',
        kind: [this.priceList[0].title],
        paymentType: '',
        depId: '',
        roomId: '',
        remark: ''
      },
      currentInfo: {},
      searchParams: {
        limit: true,
        startDate: '',
        endDate: '',
        buildingId: '',
        pageNum: 1,
        pageSize: 10,
      }
    }
  },
  props: {
    priceList: {
      type: Array,
      default: function () {
        return [
          {
            type: 0,
            price: '',
            number: 1,
            money: '',
            remark: '',
            title: '水费充值'
          },
          {
            type: 1,
            price: '',
            number: 1,
            money: '',
            remark: '',
            title: '电费充值'
          }
        ]
      }
    },
    types: {
      type: Array,
      default: function () {
        return [0,1]
      }
    }
  },
  mounted () {
    this.getInfo()
    this.getAllDerp()
  },
  mixins: [minxins],
  methods: {
    onSearch () {
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onDeptModalChange () {
      this.modalForm.roomId = ''
    },
    onFeeChange (index) {
      this.priceList[index].money = ''
      if (Number(this.priceList[index].price) && Number(this.priceList[index].number)) {
        this.priceList[index].money = (this.priceList[index].price * this.priceList[index].number).toFixed(2)
      }
    },
    onDeptChange () {
      this.searchParams.buildingId = ''
      this.getBuildings(this.searchParams.depId)
    },
    onSuccess (res) {
      if (res.code == '200') {
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.onSearch()
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    onLead () {
      this.$fetch.post('/department/importDeps', {
        ...this.modalForm,
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.dialogFormVisible = false
          this.onSearch()
        }
      })
    },
    onAddShow () {
      for(let i in this.modalForm) {
        this.modalForm[i] = ''
      }
      if (this.priceList.length == 1) {
        this.modalForm.kind = [this.priceList[0].title]
      }
      this.modalForm.depId = this.$store.state.userInfo.depId
      this.currentInfo = {
        type: 'add'
      }
      this.priceList.map(item => {
        item.price = ''
        item.money = ''
        item.number = ''
        item.remark = ''
      })
      this.operaType = 'add'
      this.dialogFormVisible = true
    },
    onSure () {
      if (this.operaType == 'add') {
        this.onAddData()
      } else {
        this.onEditData()
      }
    },
    onEditData () {
      this.loading = true
      let currentItem = {}
      this.priceList.map(item => {
        if (item.type == this.currentInfo.type) {
          currentItem = item
        }
      })
      this.$fetch.post('/chargeRecord/update', {
        ...this.modalForm,
        ...currentItem
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.dialogFormVisible = false
          this.getInfo()
        }
      })
    },
    onEdit (params) {
      this.currentInfo = params
      for(let i in this.modalForm) {
        this.modalForm[i] = params[i]
      }
      this.priceList.map(item => {
        if (item.type == this.currentInfo.type) {
          item.price = this.currentInfo.price
          item.number = this.currentInfo.number
          item.money = this.currentInfo.amount
          item.remark = this.currentInfo.remark
          item.paymentType = this.currentInfo.paymentType
          if (this.currentInfo.name) {
            item.name = this.currentInfo.name
          }
        }
      })
      this.operaType = 'edit'
      this.dialogFormVisible = true
    },
    onAddData () {
      let priceList = []
      this.priceList.map(item => {
        if (item.number && item.price) {
          priceList.push(item)
        }
      })
      this.loading = true
      this.$fetch.post('/chargeRecord/add', {
        ...this.modalForm,
        recordInfos: priceList,
        name: this.priceList[0].name ? this.priceList[0].name : undefined
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.dialogFormVisible = false
          this.onSearch()
        }
      })
    },
    getInfo () {
      this.$bus.$emit('createLoad', true)
      this.$fetch.post('/chargeRecord/queryChargeRecords', {
        ...this.searchParams,
        types: this.types
      }).then(res => {
        this.$bus.$emit('createLoad', false)
        this.tableData = []
        this.total = 0
        if (res.code == 200 && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        }
      })
    },
    onReset () {
      for(let i in this.searchParams) {
        if (i != 'limit' && i != 'pageNum' && i != 'pageSize') {
          this.searchParams[i] = ''
        }
      }
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    getAllDerp () {
      this.$fetch.post('/department/queryDeps', {
        limit: false
      }).then(res => {
        if (res.code == 200 && res.result) {
          this.depList = res.result.list
        }
      })
    },
    onExport () {
      this.btnLoading = true
      let names = '水电费充值'
      if (this.types.indexOf(8) > -1) {
        names = '自定义账单'
      }
      if (this.types.indexOf(6) > -1) {
        names = '装修材料'
      }
      this.$fetch.post('/chargeRecord/exportChargeRecords', {
        ...this.searchParams,
        types: this.types
      }, { 
        responseType: 'blob', 
      }).then(res => {
        this.btnLoading = false
        let name = names + this.$moment(new Date()).format('YYYY-MM-DD') + '.xls'
        this.$utils.exportFile(res, name)
      })
    },
    onSizChange (size) {
      this.searchParams.pageSize = size
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onCurrentChange (num) {
      this.searchParams.pageNum = num
      this.getInfo()
    },
    onPrint (params) {
      window.open(`/manage/chargeRecord/exportChargeRecord?id=${params.id}`)
    }
  }
}
</script>
<style lang="less" scoped>
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
}
</style>